.informacion {
    position: relative;
    padding-top: 50px;
    
    @include mq(md) {
        padding-top: 100px;
        height: 100%;
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center; 
        min-height: 100%;
        padding-top: 30px;
    }

    &__wrapper {
        &:not(:last-child) {
            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid $darkGray;
        }
    }
    
    &__group {
        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    &__title {
        color: $blue;
        text-transform: uppercase;
        margin: 0;
        margin-bottom: 20px;
    }

    &__text {
        color: $darkGray;
        margin: 0;
    }

    &__button {
        display: inline-block;
        background: $gold;
        color: $blue;
        height: 40px;
        line-height: 40px;
        width: 60%;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
        text-decoration: none;
        margin-top: 20px;
    }

    &__map {
        overflow: hidden;
        background: $blue;
        height: 100vw;
        position: relative;
                
        @include mq(md) {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 50%;
            height: 100vh;
            margin-left: auto;
        }
        
        @include customMq(1200px) {
            width: calc(50% + 175px);
        }
        
        @include customMq(1400px) {
            width: calc(50% + 195px);
        }
        
        iframe {
            width: 100%;
            height: 100%;
            border: 0;        
            opacity: .9;
            mix-blend-mode: screen;
            filter: grayscale(100%);
        }

        &:after {
            position: absolute;
            content: '';
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $blue;
            mix-blend-mode: multiply;
            opacity: .7;
        }

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            z-index: 100;
        }
    }
}