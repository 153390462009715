.header {
    
    $h: &;
    
    background: $blue;
    position: fixed;
    width: 100%;
    z-index: 1000;
    border-bottom: 1px solid rgba(255,255,255,.3);
    
    @include mq(md) {
        position: absolute;
    }
    
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }    
    
    &__logo {
        position: relative;
        min-width: 100px;
        height: 50px;
        padding: 15px 30px 15px 15px;
        border-right: 1px solid rgba(255,255,255,.3);
        display: flex;
        justify-content: flex-start;
        z-index: 2000;
        align-items: center;

        @include mq(md) {
            height: 100px;
            padding: 30px 50px 30px 0;
        }

        img {
            height: 100%;
        }
    }

    &__wrapper {
        display: flex;
    }

    &__nav {
        @include trans;

        opacity: 0;
        width: 0;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        width: 100vw;
        transform: translateX(-100%);
        background: $blue;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        
        @include mq(md) {
            position: static;
            display: block;
            opacity: 1;
            overflow: visible;
            width: auto;
            height: auto;
            margin-right: 20px;
            transform: translateX(0);
        
        }
    }
    
    &__list {
        @include mq(md) {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 40px;

            @include mq(md) {
                margin-bottom: 0;
            }
        }        
        @include mq(md) {
            margin-left: 20px;
        }

        a {
            color: $gold;
            text-transform: uppercase;
            text-decoration: none;
            font-family: $titleFont;
            font-weight: 500; 
        }
    }

    &__group {
        display: flex;
        align-items: center;

        i {
            color: $gold;
            font-size: 24px;
            margin-right: 20px;
            
            @include mq(md) {
                margin-right: 0;
            }
        }
    }

    &__language {
        position: relative;
        background: $blue;
        color: $gold;
        border: none;
        text-transform: uppercase;
        margin-right: 20px;
        font-family: $titleFont;
        font-weight: 500; 
        height: 18px;
        width: 30px;

        &:after {
            content: '';
            position: absolute;
            top: calc(50% - 3px);
            right: 0;
            transform: translateY(-50%) rotate(45deg);
            border-bottom: 3px solid $gold;
            border-right: 3px solid $gold;
            border-top: 3px solid transparent;
            border-left: 3px solid transparent;
            height: 0;
            width: 0;
        }

        &:hover {
            li:not(.-active) {
                opacity: 1;
            }
        }
        
        li {
            @include trans;
            position: absolute;
            opacity: 0;
            top: 20px;
            cursor: pointer;

            &.-active {
                opacity: 1;
                top: 0;
                cursor: default;
            }
        }
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 21px;
        z-index: 2000;

        @include mq(md) {
            display: none;
        }

        span {
            background-color: $gold;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(0);
            opacity: 1;
            overflow: visible;
            width: 100%;
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
